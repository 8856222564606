import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import IndexView from '../views/IndexView.vue'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'index',
		component: () => import('../views/loadingView.vue'),
		meta: { requiresAuth:true }
	},
	{
	  path: '/home',
	  name: 'home',
	  component: () => import('../views/IndexView.vue'),
	  meta: { requiresAuth: true }
	},
  {
    path: '/scenic',
    name: 'scenic',
	meta: { requiresAuth: true },
    component: () => import('../views/scenic/IndexView.vue'),
	
  },{
    path: '/scenic/info',
    name: 'scenicInfoDetail',
    component: () => import('../views/scenic/infoDetail.vue'),
	meta: { requiresAuth: true }
  },{
    path: '/scenic/evaluate',
    name: 'scenicInfoEvaluate',
	meta: { requiresAuth: true },
    component: () => import('../views/scenic/infoEvaluate.vue')
  },{
    path: '/map',
    name: 'map',
	meta: { requiresAuth: true },
    component: () => import('../views/scenic/infoMap.vue')
  }
	
  ,{
    path: '/evaluate',
    name: 'evaluate',
	meta: { requiresAuth: true },
    component: () => import('../views/evaluate/IndexView.vue')
  }
	,{
	  path: '/routes',
	  name: 'route',
	  meta: { requiresAuth: true },
	  component: () => import('../views/route/IndexView.vue')
	}
	,{
	  path: '/routes/info',
	  name: 'routeInfo',
	  meta: { requiresAuth: true },
	  component: () => import('../views/route/routwDetail.vue')
	},
	{
	  path: '/search',
	  name: 'search',
	  meta: { requiresAuth: true },
	  component: () => import('../views/scenic/SearchView.vue')
	}
	,{
	  path: '/my',
	  name: 'myIndex',
	  meta: { requiresAuth: true },
	  component: () => import('../views/my/IndexView.vue')
	}
	,{
	  path: '/my/browse',
	  name: 'myBrowse',
	  meta: { requiresAuth: true },
	  component: () => import('../views/my/userBrowse.vue')
	}
	,{
	  path: '/my/collect',
	  name: 'myCollect',
	  meta: { requiresAuth: true },
	  component: () => import('../views/my/userCollect.vue')
	}
	,{
	  path: '/my/comment',
	  name: 'myComment',
	  meta: { requiresAuth: true },
	  component: () => import('../views/my/userComment.vue')
	}
	,{
	  path: '/logins',
	  name: 'logins',
	  component: () => import('../views/userLogin.vue')
	}
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
